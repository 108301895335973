<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Vuokrakohteet</div>

    <v-card class="mt-2">
      <v-card-title v-if="!isGroupMode()">
        <v-spacer></v-spacer>
        <v-btn
          v-if="isAuthorized('apartment', 'create')"
          color="info"
          class="mr-1 mb-2"
          @click="alert"
        >
          Uusi kohde
        </v-btn>
        <v-btn
          v-if="isAuthorized('apartment', 'edit')"
          color="primary"
          dark
          class="mr-1 mb-2 ml-auto"
          @click="editMultipleDialog = selectedApartments.length > 0 ? true : false"
          >Muokkaa valittuja</v-btn
        >
        <v-btn
          v-if="isAuthorized('apartment', 'read')"
          color="primary mr-1 mb-2 ml-auto"
          @click="download"
        >
          Lataa excel
        </v-btn>
      </v-card-title>

      <v-container>
        <v-form ref="form">
          <v-row dense>
            <v-col style="padding-bottom: 0px; padding-top: 0px">
              <span class="font-weight-bold primary--text">Hae vuokrakohteita</span>
            </v-col>
          </v-row>

          <v-row class="mb-1" dense>
            <v-col
              cols="10"
              sm="5"
              md="4"
              lg="3"
              xl="2"
              style="padding-bottom: 0px; padding-top: 0px"
            >
              <v-checkbox
                v-model="searchByDates"
                label="Hae vapaita aikavälillä"
                style="margin-top: 8px"
                class="pr-2"
                hide-details
              ></v-checkbox>

              <v-checkbox
                v-if="searchByDates"
                v-model="showEndDate"
                style="margin-top: 8px"
                label="Toistaiseksi voimassa oleva sopimus"
                @change="dates.endDate = null"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row v-if="searchByDates" dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-menu
                ref="menuStart"
                v-model="menuStart"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormattedStart"
                    v-bind="attrs"
                    :rules="required"
                    v-on="on"
                    outlined
                    dense
                    label="Alkaen"
                    append-icon="mdi-calendar"
                    v-prevent-manual-input
                    :readonly="true"
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates.startDate"
                  first-day-of-week="1"
                  @input="menuStart = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <div v-if="!showEndDate">
                <v-menu
                  ref="menuEnd"
                  v-model="menuEnd"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedEnd"
                      dense
                      outlined
                      v-bind="attrs"
                      :rules="required"
                      v-on="on"
                      label="Päättyen"
                      append-icon="mdi-calendar"
                      v-prevent-manual-input
                      :readonly="true"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates.endDate"
                    first-day-of-week="1"
                    @input="menuEnd = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6" sm="3" md="2" xl="1">
              <v-text-field
                v-model.number="searchFields.areaMin"
                type="number"
                placeholder="Min"
                label="Min"
                suffix="m²"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" xl="1">
              <v-text-field
                v-model.number="searchFields.areaMax"
                type="number"
                placeholder="Max"
                label="Max"
                suffix="m²"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" xl="1">
              <v-text-field
                v-model.number="searchFields.estimatedRentMin"
                type="number"
                placeholder="Min"
                label="Min"
                suffix="€"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" xl="1">
              <v-text-field
                v-model.number="searchFields.estimatedRentMax"
                type="number"
                placeholder="Max"
                label="Max"
                suffix="€"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="3" md="2" xl="2">
              <v-select
                v-model="searchFields.houseType"
                :items="globalValues.houseTypes"
                label="Tyyppi"
                outlined
                dense
                hide-details
                clearable
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mb-1" dense>
            <v-col cols="12" md="6" lg="5" xl="4">
              <v-text-field
                v-model="search"
                label="Osoite, kohde, postinum, kaupunki, omistaja... "
                single-line
                hide-details
                solo
                @keydown.enter.prevent="getDataFromApi()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-row dense>
          <v-col cols="6" md="4">
            <v-btn class="mr-5 mb-2" color="info" @click.prevent="getDataFromApi()">Etsi</v-btn>
            <v-btn class="mb-2" color="error" outlined @click="resetForm()">Tyhjennä</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        v-model="selected"
        :show-select="!isGroupMode()"
        item-key="_id"
        :headers="computedHeaders"
        :items="apartments"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- CreatedBy -->
        <template #[`item.createdBy`]="{ item }">
          <span
            class="primary--text"
            style="cursor: pointer"
            @click="switchToAccount(item.createdBy, '/apartments')"
            ><strong>{{ item.createdBy.name }} </strong></span
          >
        </template>

        <!-- Apartment address / Overview link -->
        <template #[`item.address`]="{ item }">
          <router-link
            v-if="!isGroupMode()"
            style="text-decoration: none"
            :to="`/apartment/overview/${item._id}`"
          >
            <div class="font-weight-bold">
              <span
                >{{ item.address
                }}<span v-if="item.apartmentNumber">, {{ item.apartmentNumber }}</span>
              </span>
            </div>
          </router-link>
          <div v-else class="font-weight-bold">
            <span
              >{{ item.address
              }}<span v-if="item.apartmentNumber">, {{ item.apartmentNumber }}</span>
            </span>
          </div>
          <div>
            {{ item.zipCode }}, {{ item.city }}
            <span
              ><v-icon
                size="15"
                style="margin-bottom: 2px; margin-left: 3px"
                @click="openApartmentInfoDialog(item)"
                >mdi-eye-outline</v-icon
              ></span
            >
          </div>
        </template>

        <!-- Condominium -->
        <template #[`item.condominium`]="{ item }">
          <span>
            {{ item.condominium.name }}
          </span>
        </template>

        <!-- Contract -->
        <template #[`item.contract`]="{ item }">
          <div v-if="!item.activeForRent" class="error2--text">Ei vuokrattavana</div>

          <div>
            Käytössä {{ getActiveContracts(item.contract).length }} /
            {{ item.concurrentContracts }}
          </div>

          <!-- No contracts -->
          <span v-if="item.contract.length == 0" class="error--text">Ei sopimuksia</span>

          <!-- Active contracts -->
          <div
            v-else-if="getActiveContracts(item.contract).length > 0"
            v-for="(contract, index) in getActiveContracts(item.contract)"
            :key="'a' + index"
          >
            <!-- GROUPMODE -->
            <router-link
              v-if="!isGroupMode()"
              style="text-decoration: none"
              :to="`/rental-contract/overview/${contract._id}`"
            >
              <!-- Not signed -->
              <span v-if="!contract.signed" class="draft--text">
                Luonnos {{ createContractText(item, contract) }}
              </span>

              <div v-else>
                <!-- Revoked -->
                <span v-if="contract.revoked && contract.revokedDate" class="warning--text">
                  {{ createContractText(item, contract) }}
                </span>

                <!-- Active -->
                <span v-else class="success--text">
                  {{ createContractText(item, contract) }}
                </span>
              </div>
            </router-link>

            <!-- NORMAL -->
            <div v-else>
              <!-- Not signed -->
              <span v-if="!contract.signed" class="draft--text">
                Luonnos {{ createContractText(item, contract) }}</span
              >

              <div v-else>
                <!-- Revoked -->
                <span v-if="contract.revoked && contract.revokedDate" class="warning--text">
                  {{ createContractText(item, contract) }}
                </span>

                <!-- Active -->
                <span v-else class="success--text">
                  {{ createContractText(item, contract) }}
                </span>
              </div>
            </div>
          </div>

          <!-- InActive contracts -->
          <span v-else class="draft--text">Ei voimassa olevia sopimuksia</span>
        </template>

        <!-- Estimated rent -->
        <template #[`item.estimatedRent`]="{ item }">
          <p v-if="item.area && item.estimatedRent" style="font-size: 11px">
            {{ (item.estimatedRent / item.area).toFixed(2) }} €/m²
          </p>
          <span>{{ formatCurrency(item.estimatedRent) }}</span>
        </template>

        <!-- Area-->
        <template #[`item.area`]="{ item }">
          <span>{{ item.area ? item.area + " m²" : "" }}</span>
        </template>

        <!-- Owners -->
        <template #[`item.owners`]="{ item }">
          <span v-html="renderOwners(item)"></span>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="isAuthorized('form', 'edit')"
              title="Vuokrakohteen lomakkeet"
              small
              :class="{
                hasForms: getFormStatus(item).hasForms && !getFormStatus(item).hasActiveForms,
                hasActiveForms: getFormStatus(item).hasActiveForms,
              }"
              @click="attachFormToApartment(item)"
              >mdi-form-select</v-icon
            >
            <v-icon
              v-if="isAuthorized('apartment', 'create')"
              title="Luo kopio"
              small
              @click="createDuplicate(item)"
              >mdi-content-copy</v-icon
            >
            <v-icon
              v-if="isAuthorized('apartment', 'read')"
              title="Muokkaa"
              small
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon
              v-if="isAuthorized('apartment', 'read')"
              :class="{ 'info--text': hasItems(item.files) }"
              v-model="showFileDialog"
              title="Liitä dokumentteja"
              small
              @click="
                apartmentId = item._id;
                showFileDialog = true;
              "
              >mdi-paperclip</v-icon
            >
            <v-icon
              v-if="isAuthorized('apartment', 'delete')"
              title="Poista"
              small
              @click="deleteItem(item)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
        <template #no-data>
          <h2>Ei vuokrakohteita</h2>
        </template>
      </v-data-table>
    </v-card>
    <!-- Attach form -->
    <attach-form-dialog
      v-model="attachForm"
      title="Liitä lomake vuokrakohteeseen"
      attachTo="apartment"
    ></attach-form-dialog>
    <!-- Edit multiple apartments -->
    <edit-multiple-apartments v-model="editMultipleDialog"></edit-multiple-apartments>
    <!-- Apartment info dialog -->
    <apartment-info-dialog v-model="apartmentInfoDialog" sm="12" md="6" lg="6" />
    <!-- File dialog -->
    <File
      v-model="showFileDialog"
      :document-id="apartmentId"
      title="Lataa vuokrakohteen liite"
      bucket="vuokranet-vuokrakohteet"
      document="apartment"
      model="apartment"
      @sendnewfiles="replaceFilesArray"
    ></File>
  </div>
</template>

<script>
import EditMultipleApartments from "../../components/Apartment/EditMultipleApartments.vue";
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import AttachFormDialog from "@/components/Form/AttachFormDialog.vue";
import ApartmentInfoDialog from "@/components/Apartment/ApartmentInfoDialog";
import File from "../../components/File";
import moment from "moment";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import globalValues from "../../configs/globalValues";
import { getFormStatuses } from "@/utils/formHelpers";

export default {
  title: "Vuokrakohteet",

  components: {
    File,
    EditMultipleApartments,
    AttachFormDialog,
    ApartmentInfoDialog,
  },

  mixins: [mixins],

  data: () => ({
    apartmentId: null,
    showFileDialog: false,
    editMultipleDialog: false,
    attachForm: false,
    selected: [],
    searchByDates: false,
    showEndDate: false,
    loading: true,
    options: {},
    dialog: false,
    apartmentInfoDialog: false,
    search: "",
    searchFields: {
      areaMin: null,
      areaMax: null,
      estimatedRentMin: null,
      estimatedRentMax: null,
      houseType: null,
    },
    headers: [
      {
        text: "Tili",
        value: "createdBy",
        show: [1],
      },
      {
        text: "Osoite",
        value: "address",
        show: [0, 1],
      },
      { text: "Tyyppi", value: "houseType", show: [0, 1] },
      { text: "Kiinteistö", value: "condominium", show: [0, 1] },
      {
        text: "Pinta-ala",
        value: "area",
        show: [0, 1],
      },
      {
        text: "Markkinavuokra",
        value: "estimatedRent",
        show: [0, 1],
      },
      {
        text: "Omistajat",
        value: "owners",
        show: [0, 1],
      },
      { text: "Sopimus", value: "contract", sortable: false, show: [0, 1] },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
        show: [0],
      },
    ],
    menuStart: false,
    menuEnd: false,
    dateFormattedStart: "",
    dateFormattedEnd: "",
    dates: {
      startDate: null,
      endDate: null,
    },
    required: [(v) => !!v || "Pakollinen kenttä"],
    globalValues,
  }),

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("apartment", ["apartments", "totalLength", "selectedApartments"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),

    computedHeaders() {
      return this.headers.filter((el) => {
        if (this.isGroupMode()) {
          if (el.show.includes(1)) return el;
        } else {
          if (el.show.includes(0)) return el;
        }
      });
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    selected(val) {
      const apartments = [];

      if (val.length > 50) {
        this.showPopup("Et voi valita enempää kuin 50 vuokrakohdetta kerralla.", "error");
        return this.selected.pop();
      }

      val.forEach((item) => {
        apartments.push({
          _id: item._id,
          address: `${item.address}, ${item.apartmentNumber}`,
        });
      });

      this.setSelectedApartments(apartments);
    },

    totalLength() {
      this.options.page = 1;
    },

    "dates.endDate"() {
      this.dateFormattedEnd = this.formatDate(this.dates.endDate);
    },
    "dates.startDate"() {
      this.dateFormattedStart = this.formatDate(this.dates.startDate);
    },
  },

  methods: {
    ...mapActions("apartment", ["getAllApartments", "deleteApartment", "searchFreeApartments"]),
    ...mapMutations("apartment", [
      "setSelectedApartments",
      "setApartmentCopy",
      "setApartment",
      "setDefaultApartment",
    ]),
    ...mapMutations("form", ["setAttachment"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapGetters("account", ["getSelectedAccounts"]),

    createContractText(apartment, contract) {
      const roomsInfo = this.getRooms(apartment, contract);
      if (roomsInfo !== "") {
        return `Sop. ${contract.contractNumber}, (${roomsInfo}), ${contract.tenant.name}`;
      } else {
        return `Sop. ${contract.contractNumber}, ${contract.tenant.name}`;
      }
    },

    getRooms(apartment, contract) {
      const rooms = apartment.rooms || [];
      const roomIds = contract.apartment.roomIds || [];

      if (rooms.length === 0 || roomIds.length === 0) return "";
      let rentedRooms = "";

      roomIds.forEach((roomId, idx) => {
        const index = rooms.findIndex((room) => String(room._id) === String(roomId));
        if (index != -1)
          rentedRooms += idx < roomIds.length - 1 ? `${rooms[index].name}, ` : rooms[index].name;
      });

      return rentedRooms;
    },

    resetForm() {
      this.searchByDates = false;
      this.dates.startDate = null;
      this.dates.endDate = null;
      this.searchFields.areaMin = null;
      this.searchFields.areaMax = null;
      this.searchFields.estimatedRentMin = null;
      this.searchFields.estimatedRentMax = null;
      this.searchFields.houseType = null;
      this.search = "";

      this.getDataFromApi();
    },

    async getDataFromApi() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        // Save main search term
        this.saveMainSearchTerm(this.search);

        let search = this.search.trim().toLowerCase();
        let url = `/api/v1/apartment/getAll?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}&searchByDates=${this.searchByDates}`;

        await this.getAllApartments({
          url,
          searchFields: this.searchFields,
          dates: this.dates,
          accountSelector: this.getSelectedAccounts(),
        });

        this.loading = false;
      } else {
        this.showPopup("Tarkista pakolliset kentät", "error");
      }
    },

    getActiveContracts(contracts) {
      let contrs = [];
      const today = moment().format("YYYY-MM-DD");

      for (let i = 0; i < contracts.length; i++) {
        // If revoked Date, check if contract is already ended
        if (contracts[i].revokedDate && contracts[i].revoked) {
          if (moment(moment(contracts[i].revokedDate).format("YYYY-MM-DD")).isSameOrAfter(today)) {
            contrs.push(contracts[i]);
          }
        } else {
          // Check if lease has endDate
          if (contracts[i].lease.leaseType == "Määräaikainen") {
            if (
              moment(moment(contracts[i].lease.endDate).format("YYYY-MM-DD")).isSameOrAfter(today)
            ) {
              contrs.push(contracts[i]);
            }
          } else {
            contrs.push(contracts[i]);
          }
        }
      }

      return contrs;
    },

    getFormStatus(item) {
      return getFormStatuses(item);
    },

    renderOwners(item) {
      let persons = "";

      if (item.owners.length > 0) {
        item.owners.forEach((el, idx) => {
          idx < item.owners.length - 1
            ? (persons += `${el.ownerId.name}, `)
            : (persons += `${el.ownerId.name}`);
        });
      } else {
        if (this.isGroupMode()) {
          return item.createdBy.name;
        } else {
          return this.$store.state.account.currentUser.currentAccount.name;
        }
      }
      return persons;
    },

    replaceFilesArray(item) {
      const index = this.apartments.findIndex((obj) => obj._id === item._id);
      if (index !== -1) {
        this.apartments[index].files = item.files;
      }
    },

    editItem(item) {
      this.$router.push("/apartment/edit/" + item._id);
    },

    openApartmentInfoDialog(item) {
      this.setApartment(item);
      this.apartmentInfoDialog = true;
    },

    createDuplicate(item) {
      // set default apartment to state
      this.setDefaultApartment();
      // Set copy to state
      this.setApartmentCopy(item);
      // Push to create form
      this.$router.push(`/condominium/${item.condominium._id}/create-apartment?createCopy=true`);
    },

    async deleteItem(item) {
      if (
        confirm(
          "Haluatko varmasti poistaa vuokrakohteen: " + item.address + ", " + item.apartmentNumber
        )
      ) {
        if (item?.assignment?.inUse && !item?.assignment?.endDate) {
          return this.showPopup(
            "Et voi poistaa vuokrakohdetta, jossa toimeksiantosopimus on voimassa tai sopimukselle ei ole määritetty päättymispäivää.",
            "error"
          );
        }
        this.deleteApartment(item._id);
      }
    },

    async download() {
      try {
        let res = await axiosMethods.get("/api/v1/apartment/downloadCsv");

        const additionalInfo = [
          `Vuokrakohteet`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin päivämäärä: ${moment().format("DD.MM.YYYY")}`,
        ];

        const sumColumns = [
          "Pinta-ala",
          "Huoneluku",
          "Ostohinta veroineen",
          "Markkinahinta",
          "Markkinavuokra",
          "Hoitovastike",
          "Vesimaksu",
          "Saunamaksu",
          "Pyykkitupamaksu",
          "Autopaikkamaksu",
          "Yhtiölainat",
          "Oletusvakuus",
          "Sähkömaksu",
        ];

        const buffer = await this.createExcelBuffer(res.data.data, {
          addInfo: true,
          additionalInfo,
          sumColumns,
        });
        this.downloadExcel(buffer, "Vuokrakohteet");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    attachFormToApartment(item) {
      this.setAttachment({ field: "apartment", val: { ...item } });
      this.attachForm = true;
    },

    alert() {
      alert("Luo uusi kohde taloyhtiöiden kautta, kyseisen taloyhtiön riviltä, (+)-ikonista.");
    },
  },
};
</script>

<style scoped>
br {
  content: "A";
  display: block;
  margin-bottom: 0px;
}

.hasForms {
  color: var(--v-info-base);
}
.hasActiveForms {
  color: var(--v-success-base);
}
</style>
